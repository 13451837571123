.teams-list {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: ac(40px, 34px);

  .team-member {
    margin: 0;
    width: calc(33% - (20px / 2));
  }

  @mixin media 1100 {
    .team-member {
      width: calc(50% - (20px / 2));
    }
  }

  @mixin media 651 {
    .team-member {
      width: 100%;
    }
  }

  + .pagination-container {
    margin-top: ac(40px, 32px);
  }
}