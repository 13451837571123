.swiper-pagination {
  --swiper-pagination-bullet-inactive-color: var(--cl-dark-grey);
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-size: ac(16px, 14px);
  --swiper-pagination-bullet-horizontal-gap: ac(3px, 2px);
  --swiper-pagination-color: var(--cl-dark-grey);

  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  position: relative !important;
  margin-top: ac(34px, 30px);
  margin-left: auto;
  margin-right: auto;

  .swiper-pagination-bullet {
    transition: opacity 0.3s ease;
    box-shadow: 0 0 3px rgba(51, 51, 51, 0.4);
    border-radius: 0;

    &:hover {
      &:not(.swiper-pagination-bullet-active) {
        opacity: 0.7;
      }
    }
  }

  &.white-type {
    --swiper-pagination-bullet-inactive-color: var(--cl-light-grey);
    --swiper-pagination-color: var(--cl-light-grey);

    .swiper-pagination-bullet {
      box-shadow: 0 0 3px rgba(211, 211, 211, 0.4);
    }
  }
}

.swiper-arrow-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-shadow: 0 0 10px color-mod(var(--cl-white) a(50%));
  transition: all .3s ease;

  .w-icon-slider-left, .w-icon-slider-right {
    font-size: ac(40px, 24px);
    color: var(--cl-white);
    transition: color .3s ease;
  }

  &:hover {
    text-shadow: 0 0 10px color-mod(var(--cl-blue) a(50%));
    .w-icon-slider-left, .w-icon-slider-right {
      color: var(--cl-blue);
    }
  }

  &.swiper-button-disabled {
    opacity: .5;
  }

  &.swiper-button-lock {
    display: none;
  }

  &.blue-btn {
    .w-icon-slider-left, .w-icon-slider-right {
      color: var(--cl-blue);
    }

    &:hover {
      .w-icon-slider-left, .w-icon-slider-right {
        color: var(--cl-black);
      }
    }
  }
}

.swiper-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ac(24px, 20px);

  .swiper-arrow-btn {
    margin-top: ac(30px, 24px);
  }
}

.pagination-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.previous, .number-block, .next {
  transition: all .3s ease;
}

.number-block.w-inline-block {
  &.is-active {
    background: lightgrey;
    border-color: var(--cl-blue);
  }
}

.next-block, .number-inner-block, .previous-block {
  padding: ac(10px, 8px) ac(20px, 10px);

  .text-2 {
    font-size: ac(18px, 16px);
    line-height: 1.2;

    &.with-icon {
      display: none;
    }
  }

  &:not(.number-inner-block) {
    @mixin media 551 {
      .text-2 {
        display: none;

        &.with-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          .w-icon-slider-left, .w-icon-slider-right {
            font-size: ac(18px, 16px);
            line-height: 1.2;
          }
        }
      }
    }
  }
}