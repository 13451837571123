.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    @mixin aspect-ratio 287, 271;
    width: 100%;

    img {
      object-fit: cover !important;
    }
  }

  .text-box.team {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .content-element {
      margin-bottom: 16px;
    }

    .grid-team-socials {
      margin-top: auto;
    }
  }


}