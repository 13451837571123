/*.latest-jobs-section {
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;

  @mixin media 551 {
    .padding-cont {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}*/

.news-slider {
  overflow: visible;
  width: 100%;
  max-width: 100%;

  .news-card {
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    .card-content {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .read-more-wrap {
        margin-top: auto;
      }
    }
  }

  .swiper-arrow-btn-prev, .swiper-arrow-btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  .swiper-arrow-btn-prev {
    left: 0;
    transform: translateY(-50%) translateX(-25%);
  }

  .swiper-arrow-btn-next {
    right: 0;
    transform: translateY(-50%) translateX(25%);
  }
}