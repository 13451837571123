.logos-slider {
  width: 100%;
  max-width: 100%;

  padding: 1px;
  overflow: hidden;
  clip-path: inset(0 0 0 0);

  .swiper-wrapper {
    max-height: ac(210px, 180px);
    overflow: hidden;
    clip-path: inset(0 0 0 0);

    @mixin media 901 {
      max-height: 42vw;
    }

    @mixin media 670 {
      max-height: 43vw;
    }

    @mixin media 580 {
      max-height: 41vw;
    }

    @mixin media 451 {
      max-height: 50vw;
    }
  }

  &__item {
    @mixin aspect-ratio 200, 90;

    img {
      object-fit: contain;
    }

    &.add-filter {
      --tw-invert: invert(100%);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    }
  }

  .swiper-arrow-btn .w-icon-slider-left, .swiper-arrow-btn .w-icon-slider-right {
    font-size: ac(34px, 22px);
  }
}

.logos-section-container {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content-grid {
    width: 48%;

    .block-grid {
      width: 100%;
      max-width: 100%;
    }

    .title {
      margin-bottom: ac(24px, 20px) !important;
    }
  }

  .logos-slider {
    margin-top: ac(40px, 0px);
    max-width: 48%;
  }

  @mixin media 901 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 30px;

    .content-grid {
      width: 100%;
    }

    .logos-slider {
      max-width: 100%;
    }
  }
}

.team-logos-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .team-member.with-only-card {
    max-width: 48%;
  }

  .logos-slider {
    margin-top: ac(40px, 0px);
    max-width: 48%;
  }

  @mixin media 901 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 30px;

    .logos-slider {
      max-width: 100%;
    }

    .team-member.with-only-card {
      max-width: 100%;
    }
  }
}