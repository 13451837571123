h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
h1 {
  font-size: 38px;
  line-height: 44px;
}
h2 {
  font-size: 32px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 30px;
}
h4 {
  font-size: 18px;
  line-height: 24px;
}
h5 {
  font-size: 14px;
  line-height: 20px;
}
h6 {
  font-size: 12px;
  line-height: 18px;
}
blockquote {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  border-left: 5px solid #E2E2E2;
  font-size: 18px;
  line-height: 22px;
}
figure {
  margin: 0;
  margin-bottom: 10px;
}
figcaption {
  margin-top: 5px;
  text-align: center;
}

.content-element {
  font-family: var(--font-main);
  font-size: ac(20px, 18px);
  font-weight: 300;
  line-height: 140.00%;

  p, li, .simplebar-content {
    color: var(--cl-black);
    font-family: var(--font-main);
    font-size: ac(20px, 18px);
    font-weight: 300;
    line-height: 140.00%;

    a {
      font: inherit;
      color: var(--cl-dark-grey);
      transition: all .3s ease;

      &:hover {
        color: var(--cl-blue);
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ol {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ul {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ul {
    display: grid;
    list-style-type: none;

    li {
      padding-left: ac(24px, 20px);
      position: relative;

      &:before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        left: calc(ac(24px, 20px) / 4);
        top: 50%;
        transform: translateY(-50%);
        background: var(--cl-black);
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    display: grid;

    li {
      position: relative;
      padding-left: ac(24px, 20px);

      &:before {
        position: absolute;
        color: inherit;
        top: 0;
        left: calc(ac(24px, 20px) / 5);
        font: inherit;
        content: counter(num) '.';
        counter-increment: num;
      }
    }
  }

  p, li {
    a {
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;
      display: inline-block;
    }
  }

  &.small-size {
    font-size: ac(18px, 16px);
    p, li, .simplebar-content {
      font-size: ac(18px, 16px);
    }
  }

  &.big-size {
    font-size: ac(22px, 20px);
    p, li, .simplebar-content {
      font-size: ac(22px, 20px);
    }
  }

  &.white-color-content {
    p, li, .simplebar-content {
      color: var(--cl-white);

      a {
        color: var(--cl-blue);

        &:hover {
          color: var(--cl-medium-grey);
        }
      }
    }

    ul {
      li {
        &:before {
          background-color: var(--cl-white);
        }
      }
    }
  }

  &[data-simplebar] {
    width: calc(100% + 8px);
    --line-count: 10;
    max-height: calc((ac(20px, 18px) * 1.4) * var(--line-count));
    padding-right: 8px;

    .simplebar-track.simplebar-vertical {
      width: ac(4px, 3px);
      background: var(--cl-medium-grey);
      border-radius: 0;

      .simplebar-scrollbar {
        background: var(--cl-dark-grey);
        border-radius: 0;

        &:before {
          content: none;
        }
      }
    }

    &.small-size {
      max-height: calc((ac(18px, 16px) * 1.4) * var(--line-count));
    }

    &.big-size {
      max-height: calc((ac(22px, 20px) * 1.4) * var(--line-count));
    }
  }

  &.white-color-content {
    .simplebar-track.simplebar-vertical {
      background: var(--cl-dark-grey);

      .simplebar-scrollbar {
        background: var(--cl-blue);
      }
    }
  }
}
