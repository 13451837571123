.testimonials-slider {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-right: 10px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .title {
      margin-bottom: ac(24px, 20px) !important;
    }
  }

  &.white-color-content {
    .title {
      color: var(--cl-white);
    }
  }
}

.testimonials-content-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .content-grid {
    width: 48%;

    .block-grid {
      width: 100%;
      max-width: 100%;
    }

    .title {
      margin-bottom: ac(24px, 20px) !important;
    }
  }

  .testimonials-slider {
    max-width: 48%;
  }

  @mixin max-md {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 30px;

    .content-grid {
      width: 100%;
    }

    .testimonials-slider {
      max-width: 100%;
    }
  }
}

.content-gradient-grid.with-slider {
  .block-grid {
    @mixin media 900 {
      max-width: 89vw !important;
    }
  }
}