
:root {
  --cl-black: #000000;

  --cl-blue: #59cbe8;

  --cl-dark-blue: #0e1127;
  --cl-dark-blue-rgb: 14 17 39;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-light-grey: #ededed;

  --cl-medium-grey: #8498a0;

  --cl-grey: #606470;
  --cl-grey-rgb: 160 174 192;

  --cl-dark-grey: #222;

  --cl-medium-dark-grey: #262626;

  --cl-red: #a90506;
  --cl-red-rgb: 239 68 68;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --font-main: 'Montserrat', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);

  --header-height: 94px;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1200px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1201px);

