/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input, textarea{
  border-radius: 0;
  @mixin transparent-bg-input inherit;
}

.text-field-dark.w-input {
  color: var(--cl-white);
  @mixin transparent-bg-input var(--cl-white);
}

.text-field.w-input {
  transition: all .3s ease;
  color: var(--cl-white);
  @mixin transparent-bg-input var(--cl-white);
}

.text-field-cf8.w-input {
  transition: all .3s ease;
  color: var(--cl-white);
  @mixin transparent-bg-input var(--cl-white);
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &.jobs-search-type {
    padding: 1px 17px 14px 13px;
    width: 100%;

    .meta-checkbox-list__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: ac(50px, 42px);
      width: 100%;
      border-bottom: 1px solid #383838;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    min-width: 14px;
    height: 14px;
    border: 1px solid var(--cl-white);
    margin-right: ac(8px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-blue);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-blue);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 0;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    font-family: var(--font-main);
    color: var(--cl-white);

    a {
      font: inherit;
      display: inline-block;
      font-weight: 500;
      color: var(--cl-blue);
      text-decoration: underline;
      transition: all 0.25s ease;

      &:hover {
        color: var(--cl-light-grey);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    border-color: var(--cl-blue);

    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }

    &:hover {
      border-color: var(--cl-white);
    }
  }
}

.news-search-form {
  padding-top: 20px;

  .text-field-2 {
    margin-right: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(20px, 16px);

  .w-input, .w-select {
    margin-bottom: 0;
    height: 42px;
  }

  .w-button {
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @mixin media 651 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
}

.form-block-3.w-form {
  @mixin media 651 {
    width: 100%;
    max-width: 100%;
    min-width: initial;
    justify-content: flex-start;

    .field-wrapper-cf8 {
      width: 100%;
    }

    .form-subwrapper-cf8 {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      .field-wrapper-cf8.right {
        margin-left: 0;
      }
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 10;

  label {
    font-family: var(--font-main);
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 700;
    color: var(--cl-dark-blue);
    padding-bottom: 8px;
    cursor: pointer;
  }

  select {
    opacity: 0;
    height: 46px;
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &__inner {
      border: none;
      border-bottom: 2px solid rgba(181, 183, 195, .4);
      border-radius: 0;
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.2s ease,
      border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 10px 0 10px 0;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.8em;
      font-family: var(--font-main);
      color: var(--cl-white);
      height: 46px;

      &:hover {
        border-color: var(--cl-white);
      }
      &.error {
        border-color: var(--cl-red);
      }
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__inner {
          border-radius: 6px 6px 0 0;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          border-top: none;
          margin-top: 0;
          border-radius: 0 0 6px 6px;
        }
      }

      &.is-flipped {
        .choices__inner {
          border-radius: 0 0 6px 6px;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 0;
          border-bottom: none;
          border-radius: 6px 6px 0 0;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-white);
      opacity: 0.5;
    }

    &__list {
      padding: 0;
      background-color: transparent;

      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: 16px;
        font-weight: 500;
        line-height: 1.8em;
        font-family: var(--font-main);

        &.is-selected {
          color: var(--cl-black);
        }
      }

      &--multiple {
        color: var(--cl-dark-blue);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        background: var(--cl-white);

        .choices__item {
          color: var(--cl-dark-grey);
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        border-bottom: 1px solid var(--cl-white);
        border-right: 1px solid var(--cl-white);
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(1.5);
        transition: all 0.15s ease-in-out;
        width: 5px;
        opacity: .5;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(-135deg) scale(1.5);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-medium-grey);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 2px solid var(--cl-blue);
      z-index: 20;
      border-radius: 0;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 2px solid var(--cl-grey);
      border-radius: 8px;
      background: transparent;
      color: var(--cl-dark-grey);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  .choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
    max-height: 245px;
    overflow-y: auto;

    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
    scrollbar-width: 4px;
    -webkit-overflow-scrolling: auto;

    &::-webkit-scrollbar-thumb:vertical {
      min-height: 50px;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px!important;
      height: 2px!important;
    }

    &::-webkit-scrollbar, &::-webkit-scrollbar-track {
      width: 4px!important;
      height: 2px!important;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background:  rgba(0, 0, 0, 0.5) !important;
      border-radius: 3px!important;
      cursor: pointer;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
    }

  }

  &__list--dropdown .choices__list, .&__list[aria-expanded] .choices__list {
    max-height: 200px;
  }

  &__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 8px;

    &.is-visible {
      margin-top: 10px;
      display: flex;
    }
  }

  &__list-item {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    padding-right: 14px;
    cursor: default;
    color: var(--cl-dark-blue);

    &:after {
      content: '';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      transition: background-color 0.25s ease, opacity 0.25s ease,
      transform 0.25s ease;
      opacity: 0.5;
      background: var(--cl-dark-blue);
      clip-path: polygon(
              20% 0%,
              0% 20%,
              30% 50%,
              0% 80%,
              20% 100%,
              50% 70%,
              80% 100%,
              100% 80%,
              70% 50%,
              100% 20%,
              80% 0%,
              50% 30%
      );
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1.15);
      }
    }
  }
  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-dark-grey);
  }

  &__error {
    color: var(--cl-red);
  }

  &.white-bg-type {
    label {
      color: var(--cl-black);
    }

    .choices {
      width: 100%;
      margin-bottom: 0;
      overflow: visible;

      &__inner {
        border: none;
        border-color: var(--cl-black);
        color: var(--cl-black);

        &:hover {
          border-color: var(--cl-blue);
        }
        &.error {
          border-color: var(--cl-red);
        }
      }

      &__placeholder {
        color: #6d6e71;
        opacity: 1;
      }

      &__list {
        background-color: transparent;

        .choices__item {
          &.is-selected {
            color: var(--cl-black);
          }
        }

        &--dropdown {
          background: var(--cl-white);

          .choices__item {
            color: var(--cl-grey);
          }
        }
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        cursor: pointer;
        &:after {
          border-color: #6d6e71;
          opacity: 1;
        }
      }

      &__list--dropdown .choices__item--selectable.is-highlighted,
      &__list[aria-expanded] .choices__item--selectable.is-highlighted {
        background-color: transparent;

        &:not(.is-selected) {
          color: var(--cl-medium-grey);
        }
      }
    }
  }

  &.blogs-type {
    label {
      color: var(--cl-black);
    }

    .choices {
      width: 100%;
      margin-bottom: 0;
      overflow: visible;

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        &:after {
          right: 8px;
        }
      }

      &__inner {
        border: 1px solid #cccccc;
        color: #817f7f;
        font-size: 14px;
        line-height: 1.42857143;
        font-weight: 300;
        height: 42px;
        min-height: 42px;
        padding: 8px 20px 8px 12px;
        background: var(--cl-white);


        &:hover {
          border-color: var(--cl-blue);
        }
        &.error {
          border-color: var(--cl-red);
        }
      }

      &__placeholder {
        color: #6d6e71;
        opacity: 1;
      }

      &__list {
        font-size: 14px;
        line-height: 1.42857143;
        font-weight: 300;
        background-color: transparent;

        .choices__item {
          &.is-selected {
            color: var(--cl-black);
          }
        }

        &--dropdown {
          background: var(--cl-white);

          .choices__item {
            color: var(--cl-grey);
          }
        }
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        cursor: pointer;
        &:after {
          border-color: #6d6e71;
          opacity: 1;
        }
      }

      &__list--dropdown .choices__item--selectable.is-highlighted,
      &__list[aria-expanded] .choices__item--selectable.is-highlighted {
        background-color: transparent;

        &:not(.is-selected) {
          color: var(--cl-medium-grey);
        }
      }
    }
  }
}

.is-safari {
  .meta-select {
    .choices {

      &__list--dropdown .choices__list, .&__list[aria-expanded] .choices__list {
        &::-webkit-scrollbar {
          width: initial;
        }

        &::-webkit-scrollbar-track {
          background: initial;
          border-radius: initial;
        }

        &::-webkit-scrollbar-thumb {
          background: initial;
          border-radius: initial;
        }
      }

    }
  }
}

&:not(.is-safari) {
  .w-input.text-white {
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: darkslategrey;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 0;
    }
  }
}


.news-search-form {
  &__inputs {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: ac(20px, 16px);

    .text-field-2.w-input {
      font-family: var(--font-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 1.8em;
      @mixin transparent-bg-input #817f7f;
      background: var(--cl-white);
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.hero-search-wrapper {
  max-width: 589px;
  width: 100%;

  .form {
    max-width: 505px;
  }

  .hero-search.w-input {
    font-size: 14px;
    line-height: 1.42857143;
    font-weight: 500;
    font-family: var(--font-main);
    @mixin transparent-bg-input #6d6e71;
  }

  .meta-select {
    margin-bottom: 10px;
    min-width: 110px;

    .choices {
      &__inner {
        padding: 8px 4px;
        height: 40px;
        min-height: 40px;

        @mixin media 651 {
          border-bottom: 1px solid #6d6e71;
        }
      }

      &.is-open {
        &:not(.is-flipped) {
          .choices__list--dropdown,
          .choices__list[aria-expanded] {
            border-top: 2px solid var(--cl-blue);
            margin-top: 8px;
            border-radius: 4px;
          }
        }
      }
    }

    .choices__list .choices__item {
      font-size: 14px;
      line-height: 1.42857143;
    }
  }

  .meta-select .choices .choices__list[aria-expanded], .meta-select .choices__list--dropdown {
    min-width: 200px;
    transform: translateX(-50%);
    margin-top: 20px;
    left: 50%;
  }

  .hero-search.w-input {

    &:focus {
      border-color: transparent;
    }

    &::placeholder {
      color: #6d6e71;
    }
  }

  @mixin media 651 {
    height: auto;
    padding-bottom: 4px;

    .form {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .hero-search.w-input {
        padding-left: 4px;
        width: 100%;
        border-bottom: 1px solid #6d6e71;

        &:focus {
          border-bottom: 1px solid var(--blue);
        }
      }

      .meta-select {
        width: 100%;
      }

      .devider {
        display: none;
      }
    }
  }
}

.meta-input,
.meta-upload-file {
  label {
    font-family: var(--font-main);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: var(--cl-light-grey);
    text-transform: none;
    padding-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-blue);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8em;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: none;
    border-bottom: 2px solid rgba(181, 183, 195, .4);;
    border-radius: 0;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
    caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 5px 0;
    height: 46px;
    background: var(--cl-black);
    @mixin transparent-bg-input var(--cl-white);
    caret-color: var(--cl-white) !important;

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-white);
      opacity: 0.5;
    }

    &:focus {
      border-color: var(--cl-white);
      caret-color: var(--cl-white) !important;
    }

    &.error {
      border-color: var(--cl-red);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      caret-color: var(--cl-white) !important;
    }
  }

  textarea {
    padding-top: 10px;
    min-height: ac(80px, 60px);
    max-height: ac(80px, 60px);
    overflow-y: auto;
    resize: vertical;
    clip-path: inset(0 0 0 0);

    scrollbar-width: thin;
    scrollbar-color: var(--cl-blue) var(--cl-grey);

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--cl-grey);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 2px;
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-white);
  }

  &__error {
    color: var(--cl-red);
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    padding-bottom: 16px;
  }

  &__input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8em;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: 2px dashed var(--cl-white);
    border-radius: 0;
    transition: all 0.3s ease;
    padding: 8px ac(16px, 12px);
    cursor: pointer;
    position: relative;
    min-height: ac(70px, 50px);

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
    }

    &:hover {
      border-color: var(--cl-blue);
    }
  }

  &__info {
    padding-top: 6px;
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    text-align: center;
    color: var(--cl-blue);
    margin-left: auto;
    margin-right: auto;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-medium-grey);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }

  &.btn-styles-type {
    .meta-upload-file__input {
      border: 1px solid var(--cl-white);
      border-radius: 6px 30px 30px 6px;
      min-width: 176px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 44px;
    }

    .meta-upload-file__name {
      color: var(--cl-white);
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease;
      font-family: var(--font-main);
      @mixin max-line-length-one;
      max-width: 290px;
      pointer-events: none;

      @mixin media 350 {
        max-width: 240px;
      }
    }

    &:hover {
      .meta-upload-file__input {
        border-color: var(--cl-blue);
      }

      .meta-upload-file__name {
        color: var(--cl-blue);
      }
    }
  }
}
