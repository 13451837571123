.page-not-found-section {
  background: var(--cl-black);
  background-image: url('../images/icon-asset-black-2-cut-left.svg');
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 600px;

  &__wrap {
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-bottom: ac(32px, 24px) !important;
    text-align: center;
    line-height: normal;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: ac(50px, 32px) !important;
    line-height: normal;
    width: 100%;
    max-width: 600px;
  }
}