.teams-slider-container {
  width: 100%;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .swiper-arrow-btn-prev, .swiper-arrow-btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    text-shadow: none;
  }

  .swiper-arrow-btn-prev {
    left: -5px;
    transform: translateY(-50%) translateX(-100%);
  }

  .swiper-arrow-btn-next {
    right: -5px;
    transform: translateY(-50%) translateX(100%);

  }
}

.teams-slider {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  .team-member {
    height: auto;
    margin: 0;
  }
}
@media (max-width:479px){
  .teams-slider-container{
    .swiper-arrow-btn-prev{
      transform: translateY(-50%) translateX(-40%);
    }
    .swiper-arrow-btn-next{
      transform: translateY(-50%) translateX(40%);
    }
  }
}