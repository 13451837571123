:root {
  --plyr-color-main: var(--cl-blue);
  --plyr-video-control-color: var(--cl-white);

  /*--plyr-control-spacing: ac(76px, 44px);*/

  @mixin media 375 {
    --plyr-control-spacing: 6px;
  }
}

.plyr__control--overlaid {
  background: transparent !important;
  border: none !important;
  transform: scaleX(1.1) translateX(-48%) translateY(-50%);

  &:hover {
    background: transparent !important;
  }

  --plyr-control-icon-size: ac(66px, 44px);
}

.plyr-hide-controls {
  .plyr__controls {
    opacity: 0;
  }
}

.plyr {
  &__video-wrapper {
    /*&:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      background: var(--cl-black);
      opacity: 0;
      content: '';
      pointer-events: none;
      transition: all 0.3s ease;
    }*/
  }

  &.plyr--paused {
    .plyr__video-wrapper {
      /*&:after {
        opacity: 0.3;
      }*/
    }
  }
}

.plyr__poster {
  background-size: cover;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding-bottom: 20px;
  }
}

@mixin media 390 {
  .plyr__volume input[type='range'] {
    max-width: 60px;
  }
}
