.block-advantage {
  &__text-content {
    width: 100%;
    .content-element {
      width: 100%;
      text-align: center;

      p, li {
        font-weight: 300;
      }
    }
  }
}