.news-card {

  .card-content {
    transition: background-color .3s ease;
    position: relative;

    &:before {
      z-index: 10;
      pointer-events: none;
      inset: 0;
      content: "";
      border: 4px solid var(--cl-blue);
      opacity: 0;
      transition: opacity .3s ease;
      position: absolute;
    }
  }

  .read-more-link {
    position: relative;
    border-bottom: 0;

    &:before {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: var(--cl-blue);
      content: "";
      transition: width .3s ease;
    }

    &:hover {
      &:before {
        width: calc(100% + 25px);
      }
    }
  }

  &:hover {
    .card-content {
      background-color: var(--cl-medium-dark-grey);

      &:before {
        opacity: 1;
      }
    }
  }
}
