.section-contact-form {
  .tabs.w-tabs {
    .w-tab-menu {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;

      .title-tabs {
        margin-bottom: 5px;
        transition: all .3s ease;
        font-size: ac(28px, 20px);
        line-height: normal;
      }

      .w-tab-link {
        &:hover {
          &:not(.w--current) {
            .title-tabs {
              opacity: .5;
            }
          }
        }
      }
    }

    .contact-form-button {
      background-color: #ccd3d5;
      &:hover {
        background-color: #c0cacc;
      }
    }
  }

  .title-5.title {
    color: var(--cl-blue);
    margin-bottom: 24px;
  }

  .link-6.white {
    transition: all .3s ease;
    text-decoration: underline;
    text-decoration-color: transparent;

    &:hover {
      color: var(--cl-blue);
      text-decoration-color: var(--cl-blue);
    }
  }
}