.link-project-block.w-inline-block {
  overflow: hidden;
  border-radius: ac(40px, 20px);
  .img-projects {
    background: var(--cl-black) !important;

    &__bg {
      position: absolute;
      inset: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:before {
        position: absolute;
        inset: 0;
        z-index: 1;
        content: "";
        background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
      }
    }
  }

  .project-info {
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(89, 203, 232, 0.61));

    .title-project {
      font-size: ac(70px, 30px);
      line-height: normal;
    }

    .info-text-project {
      transition: all .3s ease;

      &:hover {
        color: var(--cl-blue);
      }
    }
  }

  &:nth-child(2) {
    .img-projects__bg {
      img {
        object-position: top;
      }
    }
  }
}