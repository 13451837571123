.home-hero {
  background: var(--cl-black);
  position: relative;

  &__bg {
    position: absolute;
    inset: 0;

    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: "";
      background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
    }
  }

  .hero-block {
    z-index: 10;
  }
}

.privacy-header {

  .block-privacy {
    z-index: 10;
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 20%;
    }

    /*&:before {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: "";
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .72));
    }*/
  }
}

.section-gradient {
  .image-block-margin {
    .img-video-presentation {
      background: none;
      &__bg {
        position: absolute;
        inset: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &.work-with-us-img-block {
        .img-video-presentation__bg {
          img {
            object-position: bottom;
          }
        }
      }
    }
  }
}

.image-about {
  background: none;
  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}

.about-image-block {
  @mixin aspect-ratio 630, 420;
  width: 100%;
}

.partner-logo {
  img {
    /*width: 100%;
    height: 100%;*/
    object-fit: contain;
    max-width: 205px;
    max-height: 70px;
  }

  &:nth-child(2) {
    img {
      max-width: 88px;
    }
  }

  &:nth-child(3) {
    img {
      max-width: 105px;
    }
  }

  &:nth-child(4) {
    img {
      max-width: 96px;
    }
  }

  &:nth-child(5) {
    img {
      max-width: 95px;
    }
  }

  &:nth-child(6) {
    img {
      max-width: 127px;
    }
  }
}