.fp-watermark {
    display: none;
}

html {
    scrollbar-width: thin;
    scrollbar-color: var(--cl-blue) transparent;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    background: var(--cl-black);

    color: var(--cl-white);
    font-family: var(--font-main);

    overflow-x: hidden;

    min-height: calc(100 * var(--vh));
    display: flex;

    flex-direction: column;
    align-items: stretch;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--cl-light-grey);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--cl-blue);
        border-radius: 0;
    }
}

.text-black {
    color: var(--cl-black);
}

.swiper-wrapper {
    left: 0 !important;
    &.justify-center{
        justify-content: center;
    }
}

#site {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @mixin max-md {
        overflow: hidden;
    }
}

.main {
    flex-grow: 1;
}

.padding-cont {
    padding: 0 ac(40px, 24px);
    max-width: 1920px;
}

.cont {
    margin: 0 auto;
    max-width: 1276px;
    width: perc(1276);

    @mixin max-lg {
        width: 89.33%;
    }
}

section,
header,
footer {
    position: relative;
    max-width: 100vw;
}

.swiper {
    width: 100%;
    max-width: 100%;
}

.swiper-container {
    width: 100%;
    max-width: 100%;
}

.logo {
    transition: 0.3s ease;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover {
        transform: scale(1.06);
    }
}

#bold-credits, .credit-image {
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.columns-4 {
    columns: initial;
}

.work-with-us-img-block {
    @mixin aspect-ratio 100, 82;
    background-position: bottom;
}

.image-color-fill {
    &[data-view] {
        transition: width .5s ease;
        width: 100%;

        &.animated {
            width: 0;
        }
    }

    @mixin media 991 {
        &[data-view] {
            width: 0;
        }
    }
}

.scroll-indicator-wrap {
    right: ac(50px, 0px, 1024, 1440);
}


.grecaptcha-badge {
    z-index: 100;
}

.w-input {
    &.text-white {
        color: var(--cl-white);
    }
}


.contact-form-field-wr {
    position: relative;

    .input-icon {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 52px;
        z-index: 1;
        border-right: 1px solid #ccd3d5;
        pointer-events: none;
        @media (max-width: 479px) {
            display: none;
        }
    }
}

.contact-form-field.name {
    & + .input-icon {
        background-image: url('../images/User_1User.png');
        background-position: 18px;
        background-repeat: no-repeat;
        background-size: 14px;
    }
}

.contact-form-field.email {
    & + .input-icon {
        background-image: url('../images/Email_1Email.png');
        background-position: 17px;
        background-repeat: no-repeat;
        background-size: 15px;
    }
}

.contact-form-field.phone {
    & + .input-icon {
        background-image: url('../images/Mobile_1Mobile.png');
        background-position: 19px;
        background-repeat: no-repeat;
        background-size: 11px;
    }
}

.home-hero {
    @mixin max-sm {
        padding-top: 155px;
        padding-bottom: 100px;
        height: auto;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .hero-block {
            position: relative;
            inset: initial;
            width: 100%;
        }
    }
}