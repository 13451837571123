.job-card {

  .card-content {
    transition: background-color .3s ease;
    position: relative;

    &:before {
      z-index: 10;
      pointer-events: none;
      inset: 0;
      content: "";
      border: 4px solid var(--cl-blue);
      opacity: 0;
      transition: opacity .3s ease;
      position: absolute;
    }
  }

  .read-more-link {
    position: relative;
    border-bottom: 0;

    &:before {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: var(--cl-blue);
      content: "";
      transition: width .3s ease;
    }

    &:hover {
      &:before {
        width: calc(100% + 25px);
      }
    }
  }

  &:hover {
    .card-content {
      background-color: var(--cl-medium-dark-grey);

      &:before {
        opacity: 1;
      }
    }
  }
}

.job-gradient-card {
  transition: background-color .3s ease;
  position: relative;

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    line-height: normal;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      height: 2px;
      background: var(--white);
      opacity: 0;
      width: 0;
      bottom: 0;
      transition: width 0.3s ease, opacity 0.3s ease,
      background-color 0.3s ease;
    }

    &:hover {
      &:before {
        width: 100%;
        left: 0;
        right: auto;
        opacity: 1;
        background-color: var(--cl-blue);
      }
    }
  }

  &:before {
    z-index: 10;
    pointer-events: none;
    inset: 0;
    content: "";
    border: 4px solid var(--cl-blue);
    opacity: 0;
    transition: opacity .3s ease;
    position: absolute;
  }

  &:hover {

    background-color: var(--cl-medium-dark-grey);
    &:before {
      opacity: 1;
    }
  }

}
