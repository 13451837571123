.scroll-indicator-wrap {
  cursor: pointer;
  transition: all .3s ease;

  .scroll-indicator {
    transition: all .3s ease;
    background: transparent;
    animation: moveIndicator 2s linear infinite;
  }

  @keyframes moveIndicator {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(25px);
    }
    100% {
      transform: translateY(0);
    }
  }

  &:hover {
    border-color: var(--cl-blue);

    .scroll-indicator {
      border-color: var(--cl-blue);
      background-color: var(--cl-blue);
    }
  }
}

.meta-link {
  color: var(--cl-white);
  transition: all .3s ease;

  &:hover {
    color: var(--cl-blue);
  }
}

