.careers-section {
  overflow: hidden;

  &__video {
    width: 100%;
  }

  .container-text.tag.w-container {
    @mixin media 768 {
      padding-right: 20px;
      max-width: calc(100% - 20px);
    }
  }

  &__image {
    @mixin aspect-ratio 3024, 4032;
    width: 39%;

    @mixin media 1100 {
      width: 49%;
    }

    @mixin media 768 {
      width: 100%;
    }
  }
}